












































import axios from "axios";
import {
  defineComponent,
  ref,
  useFetch,
} from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import Preload from "~/components/Preload.vue";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import SfImage from '~/modules/catalog/product/components/SfImage.vue';

export default defineComponent({
  name: "Featured",
  components: {
    Preload,
    VueSlickCarousel,
    SfImage,
  },
  setup() {
    const { config } = useConfig();
    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;
    const posts = ref([]);
    const postsData = ref([]);
    const isLoaded = ref(false);
    const image_width = config.value.amblog_post_image_width;
    const image_height = config.value.amblog_post_image_height;

    // Function to generate the full URL for a blog post's image
    const postImageUrl = (imageName: string): string =>
      `${baseMediaUrl}amasty/blog/${imageName}`;

    // onMounted(async () => {
    //   try {
    //     const { data } = await axios.get(
    //       `${baseUrl}rest/V1/amasty_blog/categories/featured`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
    //         },
    //       }
    //     );
    //     posts.value = data;
    //     postsData.value = posts.value;

    //     isLoaded.value = true;
    //   } catch (error) {
    //     isLoaded.value = true;
    //     console.error(error);
    //   }
    // });

    const { fetch, fetchState } = useFetch(async () => {
      const headers = {
        Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
      };
      const response = await axios.get(
        `${baseUrl}rest/V1/amasty_blog/categories/featured`,
        {
          headers,
        }
      );
      posts.value = response.data;
      postsData.value = posts.value;
    });
    fetch();
    return {
      postsData,
      isLoaded,
      postImageUrl,
      image_width,
      image_height,
    };
  },
});
